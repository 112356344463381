import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "F:/reactKwik/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getpair",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#getpair",
        "aria-label": "getpair permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getPair`}</h1>
    <p>{`The most obvious way to get the address for a pair is to call `}<Link to='/docs/v1/smart-contracts/factory/#getpair' mdxType="Link">{`getPair`}</Link>{` on the factory. If the pair exists, this function will return its address, else `}<inlineCode parentName="p">{`address(0)`}</inlineCode>{` (`}<inlineCode parentName="p">{`0x0000000000000000000000000000000000000000`}</inlineCode>{`).`}</p>
    <ul>
      <li parentName="ul">{`The “canonical” way to determine whether or not a pair exists.`}</li>
      <li parentName="ul">{`Requires an on-chain lookup.`}</li>
    </ul>
    <h1 {...{
      "id": "create2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#create2",
        "aria-label": "create2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CREATE2`}</h1>
    <p>{`Thanks to some `}<a parentName="p" {...{
        "href": "https://github.com/Kwikswap/kwikswap-v1-core/blob/master/contracts/KwikswapV1Factory.sol#L32"
      }}>{`fancy footwork in the factory`}</a>{`, we can also compute pair addresses `}<em parentName="p">{`without any on-chain lookups`}</em>{` because of `}<a parentName="p" {...{
        "href": "https://eips.ethereum.org/EIPS/eip-1014"
      }}>{`CREATE2`}</a>{`. The following values are required for this technique:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The `}<Link to='/docs/v1/smart-contracts/factory/#address' mdxType="Link">{`factory address`}</Link></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`salt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`keccak256(abi.encodePacked(token0, token1))`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`keccak256(init_code)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`0xbc919ae6f6f95dca1e223fc957286afa1da81529418e9f187db8a0b2d2e963bc`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`token0`}</inlineCode>{` must be strictly less than `}<inlineCode parentName="li">{`token1`}</inlineCode>{` by sort order.`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`Can be computed offline.`}</li>
      <li parentName="ul">{`Requires the ability to perform `}<inlineCode parentName="li">{`keccak256`}</inlineCode>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "examples",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#examples",
        "aria-label": "examples permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Examples`}</h2>
    <h3 {...{
      "id": "solidity",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#solidity",
        "aria-label": "solidity permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Solidity`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`address factory = 0xdD9EFCbDf9f422e2fc159eFe77aDD3730d48056d;
address token0 = 0xCAFE000000000000000000000000000000000000; // change me!
address token1 = 0xF00D000000000000000000000000000000000000; // change me!

address pair = address(uint(keccak256(abi.encodePacked(
  hex'ff',
  factory,
  keccak256(abi.encodePacked(token0, token1)),
  hex'96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f'
))));
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      